.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerRightSide {
  display: flex;
  flex-direction: column;
}

.imageContainer {
  margin-bottom: 10px;
  display: flex;
  align-self: flex-end;
}

.logo {
  object-fit: contain;
  height: 50px;
}

.tabsWrapper {
  margin-bottom: 25px;
}
