@import '~/ui/assets/styles/colors.module.scss';

.archivedChannelsList {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  background-color: $color-white;
  cursor: pointer;
}

.header {
  position: sticky;
  top: 0;
  height: 64px;
  display: flex;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: $color-white;
  padding-left: 12px;
}

.title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-left: 15px;
}
