@import '~/ui/assets/styles/colors.module.scss';

.menuItem {
  &:hover {
    background-color: $color-white-3 !important;
  }
  background-color: $color-white !important;

  & svg {
    color: $color-grey;
    width: 20px;
    height: 20px;
  }

  &:hover svg {
    color: $color-red-2;
  }

  & span {
    color: $color-black-2;
  }
}

.menuIcon {
  min-width: 35px !important;
}
