@import '~/ui/assets/styles/colors.module.scss';

.clientSection {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 0 15px 0 15px;
  background-color: $color-white;
}

.client {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid $color-grey-4;
  border-radius: 10px;
  margin: 10px 0;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;

  & svg {
    width: 20px;
    height: 20px;
    color: $color-grey-2;
  }
}

.additionalInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 9px;
}

.label {
  color: $color-grey-2;
  margin-left: 8px;
}
