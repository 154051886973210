.formTitle {
  font-weight: 300;
  margin-bottom: 20px;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonsWrapper {
  margin-top: 25px;
  display: flex;
}

.button {
  margin-right: 15px;
}

.link {
  text-decoration: none;
}
