@import '~/ui/assets/styles/colors.module.scss';

.channel {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-self: stretch;
  height: calc(100vh - 50px);
  overflow: hidden;
  background-color: $color-white-7;
}

.emptyChannel {
  align-items: center;
  justify-content: center;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  height: 100%;
  flex: 1;
}

.messages {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1;
  padding: 20px 22px 20px 13px;
  overflow: hidden auto;
}

.sectionTitle {
  background-color: $color-grey-5;
  border-radius: 7px;
  color: $color-white;
  font-size: 0.94rem;
  padding: 1px 5px;
}

.sectionTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.section {
  display: flex;
  flex-direction: column;
}
