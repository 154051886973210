@import '~/ui/assets/styles/colors.module.scss';

.medicationDeliveryEmpty {
  color: $color-black;
  font-size: 14px;
  font-weight: 300;
  margin: 3px 3px;
  align-self: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  position: sticky;
  left: 25%;
}

.pillIcon {
  width: 83px;
  height: 83px;
  -webkit-mask-size: cover;
  mask-size: cover;
  margin-top: 100px;
  background-color: $color-grey-6;
  -webkit-mask-image: url('~/ui/assets/images/pillGrey.svg');
  mask-image: url('~/ui/assets/images/pillGrey.svg');
  margin-top: 100px;
  margin-bottom: 38px;
}

.message {
  font-size: 24px;
}

.customButton {
  margin-top: 50px !important;
  margin-bottom: 200px !important;
}
