@import '~/ui/assets/styles/colors.module.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableHeader {
  display: flex;
  align-items: center;
}

.tableContainer {
  width: 100%;
}

.headerCell {
  padding-left: 33px !important;
}

.item {
  display: flex;
  justify-content: space-between;
  background-color: $color-white;
  align-items: center;
  padding: 0 10px;
  font-weight: 300;
  border-left: 1px solid $color-grey-6;
  border-right: 1px solid $color-grey-6;
  border-bottom: 1px solid $color-grey-6;
  transition: color font-weight 0.5s;

  &:first-child {
    border-top: 1px solid $color-grey-6;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &:hover {
    cursor: pointer;
    color: $color-red-2 !important;
    font-weight: 500;
  }
}

.itemActive {
  color: $color-red-2 !important;
  font-weight: 500;
}

.leftColumn {
  width: 30%;
  height: 70vh;
  overflow-y: auto;
  margin-right: 25px;
}

.leftColumn::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
}

.leftColumn::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #ece7e6;
  -webkit-box-shadow: inset 0 0 6px #ece7e6;
}

.leftColumn::-webkit-scrollbar-thumb {
  background-color: #edd0ca;
  border-radius: 10px;
}

.content {
  display: flex;
}

.actionButton {
  font-weight: 300;
  cursor: pointer;
  padding-left: 16px;
  background-color: transparent;
  border: none;
}

.tableRowHeight {
  height: 85px;
}

.description {
  font-weight: 300;
}

.buttonsWrapper {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.buttonMargin {
  margin-right: 15px;
}
