@import '~/ui/assets/styles/colors.module.scss';

.channelItemIcon {
  display: flex;
  background-color: $color-white;
  border-radius: 30px;
  border: 1px solid $color-grey-7;
  justify-content: center;
  align-items: center;
  height: 43px;
  width: 43px;
  min-width: 43px;

  & svg {
    color: $color-grey-2;
  }
}

.activeItem {
  background-color: $color-red-4;
  border-color: transparent;

  & svg {
    color: $color-red-2;
  }
}
