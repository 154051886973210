@import '~/ui/assets/styles/colors.module.scss';

.text {
  text-transform: uppercase;
  text-align: center;
  padding: 3px 10px;
  border-radius: 50px;
}

.archived {
  background-color: $color-blue-2;
  color: $color-blue-3;
}

.active {
  background-color: $color-green-2;
  color: $color-green-3;
}

.pending {
  background-color: $color-orange-2;
  color: $color-orange-3;
}

.deactivated {
  background-color: $color-white-4;
  color: $color-grey-5;
}

.locked {
  background-color: $color-grey-5;
  color: $color-white-5;
}
