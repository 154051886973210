.formTitle {
  font-weight: 300;
}

.marginTop {
  margin-top: 20px;
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.buttonWrapper {
  margin-right: 15px;
}
