@import '~/ui/assets/styles/colors.module.scss';

@keyframes pulsating {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.image {
  max-width: 386px;
  max-height: 300px;
}

.placeholder {
  width: 250px;
  height: 200px;
  animation: pulsating 2s linear infinite;
  background-image: url('~/ui/assets/images/image.svg');
  background-position: center calc(50% + 20px);
  background-size: 50px;
  background-repeat: no-repeat;
}

.hidden {
  display: none;
}
