@import '~/ui/assets/styles/colors.module.scss';

.channelList {
  display: flex;
  flex-direction: column;
  max-width: 375px;
  width: 375px;
  align-self: stretch;
  position: relative;
  height: calc(100vh - 50px);
  overflow: hidden auto;
  border-left: 1px solid $color-grey-4;
  border-right: 1px solid $color-grey-4;
  background-color: white;
}

.channelListHeader {
  position: sticky;
  top: 0;
  display: flex;
  height: 64px;
  min-height: 64px;
  align-self: stretch;
  padding: 0 0 0 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $color-white;
}

.channelListItem {
  display: flex;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  padding: 0 12px;
  background-color: $color-white;
  cursor: pointer;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  border-bottom-width: 1px;
  align-self: stretch;
  border-bottom-color: $color-grey-2;
  flex: 1;
  margin-left: 3px;
  padding-left: 12px;
  overflow: hidden;
}

.col {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 12px 0;
  flex: 1;
}

.row {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}

.iconContainer {
  display: flex;
  background-color: $color-white;
  border-radius: 30px;
  border: 1px solid $color-grey-7;
  justify-content: center;
  align-items: center;
  height: 43px;
  width: 43px;
}

.activeItem {
  background-color: $color-red-4;
  border-color: transparent;
}

.title {
  color: $color-black;
  font-size: 1.06rem;
  font-weight: 700;
  margin-right: 5px;
}

.subTitle {
  font-size: 0.94rem;
  max-width: 200px;
}

.lastMessageTime {
  font-size: 0.8rem;
  color: $color-grey-2;
  padding-bottom: 4px;
}

.rightContainer {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 12px 0 0 10px;
  display: flex;
}

.initials {
  width: 44px;
  height: 44px;
  background-color: $color-white;
  border: 1px solid $color-grey-7;

  & * {
    color: $color-black;
    font-size: 1.06rem;
  }
}

.avatar {
  width: 44px;
  height: 44px;
}

.initialsActive {
  background-color: $color-white-3;
  border: 1px solid transparent;

  & * {
    color: $color-red-2;
  }
}

.active {
  background-color: $color-white-3;
}

.message {
  color: $color-grey-2;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
