@import '~/ui/assets/styles/colors.module.scss';

.monitoredBy {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $color-grey-6;
  border-bottom-color: $color-red-4;
  border-bottom-width: 2px;
  border-top-color: transparent;
  min-width: 50px;
  min-height: 111px;
  height: 100%;
  width: 50px;
}

.column {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  align-self: stretch;
  flex: 5;
  margin-right: 10px;
}

.marginRight {
  margin-right: 5px;
}

.row {
  font-weight: 600;
  font-size: 14px;
  color: $color-grey-2;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.black {
  color: $color-black;
}

.name {
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 200px;
  font-weight: 400;
}

.arrowRight {
  transform: scale(-1);
  position: absolute !important;
  right: -30px;
}

.arrowLeft {
  position: absolute !important;
  left: -35px;
}
