.customOption {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    span {
        width: 100%;
        display: block;
    }
    .label {
        font-weight: 500;
    }
    .description {
        font-weight: 300;
        color: #736F6FDB;
    }
}