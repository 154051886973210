@import '~/ui/assets/styles/colors.module.scss';

.calendarSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  overflow: auto hidden;
  align-items: flex-start;
}
