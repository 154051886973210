@import '~/ui/assets/styles/colors.module.scss';

.tableContainer {
  width: 100%;
  border-radius: 10px;
  background-color: $color-white;
  box-shadow: 0 1px 4px #c4bcbb !important;
  margin-top: 10px;
  padding: 10px 0 0 0;
}

.filtersContainer {
  padding-top: 10px;
  padding-left: 10px;
}

.visitedPersonCell {
  cursor: pointer;

  div {
    margin-top: 5px;
  }

  h5 {
    font-size: 13px;
    margin: 0;
    padding: 0;
    font-weight: 500;
  }

  h6 {
    font-size: small;
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: $color-grey;
  }

  &:hover {
    text-decoration: underline;
    color: $color-red-2;
  }
}

.noShow {
  color: $color-red-2;
}

.editButton {
  cursor: pointer;
}

.note {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.noteContainer {
  max-width: 100%;
  max-height: 40px;
  overflow: hidden;
}
