@import '~/ui/assets/styles/colors.module.scss';

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $color-white;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  justify-content: center;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);

  & button {
    margin: 0 5px;
  }
}
