@import '~/ui/assets/styles/colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  background-color: $color-white;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
}

.editingMessage {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: flex-start;
  border-bottom: 1px solid $color-grey-4;
  margin: 0 15px;
  min-height: 67px;
  font-size: 1rem;

  & > svg {
    color: $color-red-2;
    margin: auto 20px auto 50px;
    width: 22px;
  }

  & > button {
    margin-right: -15px !important;
  }
}

.oldMessage {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  justify-content: center;
}

.channelInput {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  min-height: 60px;
}

.input {
  margin: 0 10px;
}

.label {
  color: $color-red-2;
}
