@import '~/ui/assets/styles/colors.module.scss';

.calendarRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 111px;
  width: 100%;
}

.medicationName {
  min-width: 226px !important;
}

.lastRow {
  margin-bottom: 20px;
  min-height: 0px;
}

.firstRow {
  position: relative;
  min-height: 50px;
}

.arrowButton {
  position: absolute !important;
  width: 15px !important;
  height: 36px;
  border-radius: 3px !important;
  background-color: $color-white !important;
  z-index: 1;
  min-width: 15px !important;
  padding: 0 !important;

  .img {
    fill: $color-red;
    color: $color-red;
  }
}

.prev {
  left: 231px;
  top: 7px;
  transform: scale(-1);
}

.next {
  right: 5px;
  top: 7px;
}

.hidden {
  display: none;
}
