@import '~/ui/assets/styles/colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;

  & .slick-list {
    height: 100% !important;
  }

  & .slick-track {
    height: 100%;
  }

  & .slick-slide {
    height: 100%;
    width: calc(100vw - 40px);
  }

  & .slick-slide > div {
    height: 100%;
  }
}

.slider {
  height: 80vh;
  width: 100%;
}

.navSlider {
  height: 20vh;
  max-width: 60%;
  width: 60%;
  margin: 0 auto;
}

.imageSlide {
  height: 80vh;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.innerContainer {
  max-height: 90%;
  padding-top: 2%;
  width: 70%;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
}

.imageContainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.image {
  display: flex !important;
  object-fit: contain;
  height: 90%;
  width: auto;
  align-self: center;
  overflow: hidden;
}

.name {
  background-color: $color-black-4;
  border-radius: 21px;
  height: 42px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: $color-white;
  padding: 20px 10px;
  margin-top: 10px;

  & strong {
    padding-left: 5px;
    font-weight: 700;
  }

  & .time {
    color: $color-grey;
    padding-left: 5px;
  }
}

.imageNavSlide {
  height: 20vh;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.innerNavContainer {
  max-height: 70%;
  width: 70%;
  max-width: 200px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: flex;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 5px;
    border: 1px solid transparent;
  }

  &:hover::before {
    border: 1px solid $color-grey-13;
  }
}

.activeNavSlide {
  &::before {
    border: 1px solid $color-red !important;
  }
}

.navImage {
  display: flex !important;
  object-fit: cover;
  height: 90%;
  width: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 1px 4px $color-black-4;
  align-self: center;
  position: relative;
}

.arrowButton {
  position: absolute !important;
  top: calc(40% - 22px);
  height: 44px;
  width: 64px;
  border-radius: 22px !important;
  background-color: $color-black-2 !important;

  &:hover {
    background-color: $color-black-3 !important;
  }

  & svg {
    color: $color-white;
  }
}

.arrowLeft {
  left: 12%;
}

.arrowRight {
  right: 12%;
}

.closeButton {
  position: absolute !important;
  top: 10px;
  right: 10px;

  & svg {
    color: $color-white;
  }
}

.nav100 {
  width: 12%;
}

.nav200 {
  width: 24%;
}

.nav300 {
  width: 36%;
}

.nav400 {
  width: 48%;
}
