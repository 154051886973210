@import '~/ui/assets/styles/colors.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 7px;
  color: $color-grey-12;

  & > span {
    color: $color-grey-2;
    font-size: 0.875rem;
    margin-bottom: 5px;
  }
}

.historyItem {
  padding: 5px 0;
  width: 100%;
  border-bottom: 1px solid $color-grey-14;

  &:last-child {
    border-bottom: none;
  }
}
