@import '~/ui/assets/styles/colors.module.scss';

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: $color-grey-12;
  padding: 10px 12px 0;
}

.sender {
  align-self: flex-end;

  & .fileName {
    color: $color-black;
  }

  & .bg {
    background-color: $color-black-3;
  }

  & .size {
    color: $color-grey-2;
  }
}

.col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  margin-left: 10px;
  margin-top: 2px;
}

.loadingComponent {
  display: flex;
  align-self: center;
  justify-content: center;
}

.rotateComponent {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  left: 0;
  top: 0;
  animation: spin 4s linear infinite;
}

.innerContainer {
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.bg {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  background-color: $color-orange-5;
  position: relative;
}

.fileName {
  font-size: 1.06rem;
  color: $color-orange-4;
}

.size {
  color: $color-orange-6;
}

.downloading {
  width: 200px;
}
