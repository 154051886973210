$color-white: white;
$color-white-2: #fffbfa;
$color-white-3: #fff1ee;
$color-white-4: #f4f4f4;
$color-white-5: #fff9f9;
$color-white-6: rgba(247, 242, 244, 0.9);
$color-white-7: #fafafa;

$color-black: black;
$color-black-2: #323232;
$color-black-3: rgba(0, 0, 0, 0.2);
$color-black-4: rgba(0, 0, 0, 0.7);

$color-grey: grey;
$color-grey-2: #757575;
$color-grey-3: #868383;
$color-grey-4: #e0e0e0;
$color-grey-5: #b5b5b5;
$color-grey-6: #eaeaea;
$color-grey-7: #c4bcbb;
$color-grey-8: rgba(196, 196, 196, 0.15);
$color-grey-9: rgba(196, 196, 196, 0.2);
$color-grey-10: #ebebeb;
$color-grey-11: #f0f0f0;
$color-grey-12: #323232;
$color-grey-13: #a2a2a2;
$color-grey-14: #dadcde;
$color-grey-15: #3232321a;

$color-green: green;
$color-green-2: #d0f2ee;
$color-green-3: #15c0ac;
$color-green-4: #027186;

$color-red: red;
$color-red-2: #d83713;
$color-red-3: #f44336;
$color-red-4: rgba(216, 54, 18, 0.3);

$color-orange: orange;
$color-orange-2: #ffe5bf;
$color-orange-3: #ff9800;
$color-orange-4: #b36011;
$color-orange-5: rgba(179, 96, 17, 0.2);
$color-orange-6: rgba(179, 96, 17, 0.6);

$color-blue: blue;
$color-blue-2: #d6e4fa;
$color-blue-3: #0c79d0;

:export {
  colorWhite: $color-white;
  colorGrey: $color-grey-2;
  colorRed: $color-red-2;
  colorTransparentRed: $color-red-4;
  colorBlack: $color-black;
  colorGoldenBrown: $color-orange-4;
}
