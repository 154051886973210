@import '~/ui/assets/styles/colors.module.scss';

.userItem {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  height: 56px;
}

.row {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  margin-left: 10px;
  border-top: 1px solid $color-grey-4;
  flex: 1;
  padding: 6px 0;
}

.col {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
  flex: 1;
}

.client {
  display: flex;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
}

.name {
  font-size: 15px;
  font-weight: 700;
}

.userInfo {
  font-size: 15px;
  font-weight: 400;
  color: $color-grey-5;
}

.avatar {
  width: 44px;
  height: 44px;
}

.youPostfix {
  color: $color-grey-2;
}

.lastItem {
  border-top: none;
}

.initials {
  width: 44px;
  height: 44px;
  background-color: $color-white;
  border: 1px solid $color-grey-7;

  & * {
    color: $color-black;
    font-size: 1.06rem;
  }
}
