.buttonsWrapper {
  display: flex;
  margin-top: 15px;
  justify-content: center;
}

.margin {
  margin-right: 10px !important;
}

.subTitle {
  font-weight: 600;
}

.propsContainer {
  width: 60%;
}

.label {
  border: 1px solid green !important;
  border-radius: 0 !important;
}

.combinedInputWrapper {
  display: flex;
}