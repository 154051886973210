@import '~/ui/assets/styles/colors.module.scss';

.participants {
  background-color: $color-white;
  margin-top: 10px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 0 15px;
  background-color: $color-white;
  width: 100%;
  justify-content: flex-start;
  height: 50px;

  & svg {
    width: 24px;
    height: 24px;
  }

  & > span {
    margin-left: 10px;
  }
}

.participantsCount {
  font-size: 1rem;
  flex: 1;
}

.participantsList {
  padding: 11px;
  display: flex;
  align-self: stretch;
  flex-direction: column;
}

.participant {
  padding-right: 5px;
}

.addParticipant {
  width: 50px;
  height: 50px;
}

.adminLabel {
  font-size: 0.8rem;
  font-weight: 600;
  color: $color-black-2;
}

.changeAdminButton {
  color: $color-red-2;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
}

.assignAdminTitle {
  font-size: 1.125rem;
  font-weight: 700;
  display: flex;
  flex: 1;
  margin-left: 0 !important;
}

.rightComponent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.assignAdminButton {
  width: 30px;
  height: 30px;
  padding: 5px !important;

  & svg {
    width: 15px;
    height: 15px;
  }
}
