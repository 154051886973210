@import '~/ui/assets/styles/colors.module.scss';

.container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
}
