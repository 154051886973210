@import '~/ui/assets/styles/colors.module.scss';

.container {
  margin-top: 25px;
}

.header {
  margin: 0;
}

.itemsWrapper {
  border-radius: 20px;
  border: 1px solid $color-grey-4;
}

.item {
  background-color: white;
  padding: 15px;
  border-bottom: 1px solid $color-grey-4;
  font-weight: 300;
}

.itemHeader {
  display: inline-block;
  width: 130px;
  font-weight: 600;
}

.itemDetails {
  color: $color-grey-2;
  margin-left: 25px;
  font-weight: 400;
}

.item:first-of-type {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.item:last-of-type {
  border: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
