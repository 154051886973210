@import '~/ui/assets/styles/colors.module';

.header {
  display: flex;
  justify-content: space-between;
}

.subTitle {
  font-weight: 600;
}

.date {
  color: $color-red-2;
}

.buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.buttonWrapper {
  margin-right: 15px;
}

.formWrapper {
  width: 100%;
  max-height: 500px;
  overflow: scroll;
}

.subTitle {
  font-weight: 600;
}

.row {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
}

.image {
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
  width: 30px;
  height: 30px;
}
