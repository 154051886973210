@import '~/ui/assets/styles/colors.module.scss';

.item {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 15px;
  border-bottom: 1px solid $color-grey-4;
  font-weight: 300;
}

.label {
  font-size: 0.9rem;
  width: 100px;
  min-width: 100px;
  font-weight: 600;
}

.moreLabelWidth {
  min-width: 150px;
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 81%;
}

.injection {
  margin-right: 5px;
}

.content {
  color: $color-grey;
}

.grey {
  color: $color-grey;
}

.additionalText {
  font-weight: 300;
  text-align: right;
  margin: 0;
  color: $color-red-3;
}

.restoreButton {
  margin-top: 10px;
  cursor: pointer;
}

.item:first-of-type {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.item:last-of-type {
  border: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 25px;
}
