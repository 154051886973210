@import '~/ui/assets/styles/colors.module.scss';

.channelSidebar {
  display: flex;
  flex-direction: column;
  min-width: 375px;
  background-color: $color-grey-11;
  z-index: 1;
  overflow: hidden auto;
  border-left: 1px solid $color-grey-4;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $color-white;
}

.clientSectionTitle {
  font-size: 15px;
  font-weight: 700;
}

.participantSection {
  background-color: $color-white;
  padding: 15px 20px 40px 20px;

  & img {
    width: 100px;
    height: 100px;
    border-radius: 30px;
  }

  & > div > div {
    margin-left: 15px;
  }
}
