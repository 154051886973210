@import '~/ui/assets/styles/colors.module.scss';

.calendarInitials {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: $color-green-4;
  margin-left: 3px;
}

.refused {
  color: $color-red-2;
}

.archived {
  color: $color-black-2 !important;
}

.icon {
  width: 16px;
  height: 16px;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: $color-green-4;
  mask-image: url('~/ui/assets/images/person.svg');
  -webkit-mask-image: url('~/ui/assets/images/person.svg');
  margin-right: 2px;
  display: flex;
}

.refusedIcon {
  background-color: $color-red-2;
}

.archivedIcon {
  background-color: $color-black-2;
}
