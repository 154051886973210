@import '~/ui/assets/styles/colors.module.scss';

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid $color-grey-4;
  border-radius: 20px;
}

.buttonsWrapper {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.button {
  margin-right: 15px;
}

.link {
  text-decoration: none;
}
