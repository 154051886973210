.customSingleValue {
    display: flex;

    .label {
        font-weight: 500;
        margin-right: 5px;
    }

    .description {
        font-weight: 300;
        color: #736F6FDB;
    }
}