@import '~/ui/assets/styles/colors.module.scss';

.messageContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 7px;
  color: $color-grey-12;
}

.sender {
  align-self: flex-end;

  & .col,
  .fileContainer {
    background-color: $color-grey-4;
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
  }

  & .time {
    align-self: flex-end;
  }
}

.message {
  font-size: 1.06rem;
  font-weight: 400;
}

.nickname {
  font-size: 0.8rem;
  font-weight: 700;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: $color-white;
  padding: 10px 12px;
  border-radius: 10px;
  border-top-left-radius: 0;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  max-width: 400px;
}

.profileImage {
  margin-right: 10px;
  width: 30px;
  position: relative;
}

.time {
  color: $color-grey-12;
  opacity: 0.6;
  font-size: 0.8rem;
  display: flex;
  align-self: flex-start;

  & > i {
    margin-right: 5px;
  }
}

.onlineIndicator {
  position: absolute;
  bottom: 2px;
  width: 12px;
  height: 12px;
  border: 2px solid $color-white;
  border-radius: 6px;
  background-color: $color-green-4;
  right: -3px;
}

.image {
  max-width: 386px;
  max-height: 300px;
  min-height: 200px;
  min-width: 200px;

  background-image: url('~/ui/assets/images/image.svg');
  background-position: center calc(50% + 20px);
  background-size: 50px;
  background-repeat: no-repeat;
}

.fileContainer {
  background-color: $color-white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  & .time {
    padding: 7px 10px;
  }
}

.menuItem {
  &:hover {
    background-color: $color-white-3 !important;
  }
  background-color: $color-white !important;

  & svg {
    color: $color-grey;
    width: 20px;
    height: 20px;
  }

  &:hover svg {
    color: $color-red-2;
  }

  & span {
    color: $color-black-2;
  }
}

.menuIcon {
  min-width: 35px !important;
}
