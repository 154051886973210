@import '~/ui/assets/styles/colors.module.scss';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoSection {
  border-radius: 10px;
  background-color: $color-white;
  box-shadow: 0px 1px 4px $color-grey-7;
  margin-top: 20px;
  align-self: stretch;
  padding: 10px 20px 12px;
}

.modalTitle {
  text-align: center;
}

.modalWrapper {
  padding: 20px;
}

.titleSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  button {
    width: 180px;
    display: flex;
    justify-content: center;
  }
}
