@import '~/ui/assets/styles/colors.module.scss';

.actionsCell {
  padding-left: 16px;
}

.restoreButton {
  padding: 0 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.linkView {
  text-decoration: none;
  color: $color-black;
  transition: color 0.5s;
}

.linkView:hover {
  color: $color-red-2 !important;
}

.linkView:visited {
  color: $color-black;
}

.colPadding {
  text-align: left;
}
