@import '~/ui/assets/styles/colors.module.scss';

.channelHeader {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  height: 64px;
  padding: 0 10px 0 22px;
  box-shadow: 0px 1px 4px $color-grey-7;
  justify-content: space-between;
  background-color: $color-white;
}

.title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-right: 12px;
}

.membersCount {
  font-size: 0.875rem;
  font-weight: 400;
  color: $color-grey-7;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.userItem {
  align-items: center;

  & > div {
    padding-bottom: 0;
  }
}
