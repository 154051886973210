.wrapper {
  display: flex;
  justify-content: space-between;
}

.width {
  width: 49%;
}

.modalTitle {
  text-align: center;
}

.modal {
  width: 60%;
}

.modalWrapper {
  padding: 20px;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
}