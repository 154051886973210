@import '~/ui/assets/styles/colors.module.scss';

.cellContent {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0;
  flex: 1;
}

.column {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
}

.row {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
}

.weekMode {
  padding-right: 10px;
  padding-left: 5px;
}

.separator {
  color: $color-grey-6;
  font-weight: 300;
  font-size: 18px;
}

.note {
  color: $color-black-2;
  font-size: 14px;
  font-weight: 300;
  padding: 0 10px;
}

.innerContent {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  position: relative;
  padding: 5px 0;
  width: 100%;

  &:hover::before {
    content: '';
    position: absolute;
    display: flex;
    top: -1px;
    left: 1px;
    right: 1px;
    bottom: -1px;
    border-radius: 5px;
    border: 1px dashed $color-red;
  }
}

.green {
  &:hover::before {
    border: 1px dashed $color-green-4;
  }
}

.bottomBorder {
  &::after {
    content: '';
    position: absolute;
    display: flex;
    width: 60%;
    align-self: center;
    bottom: -1px;
    border: 0.7px solid $color-grey-6;
    padding: 0 3px;
  }

  &:hover::after {
    border: none;
  }
}

.bottomBorderWeekMode {
  &::after {
    width: 85%;
  }
}

.grey * {
  color: $color-grey-5;
  &:hover::before {
    border: 1px dashed $color-red;
  }
}
