@import '~/ui/assets/styles/colors.module.scss';

.addParticipants {
  background-color: $color-white;
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.participantsList {
  padding: 11px;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  padding-bottom: 60px;
  height: 100%;
}

.participant {
  padding-right: 5px;
}

.search {
  margin: 0 15px;
}

.row {
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px 15px 0;
}

.massSelect {
  height: 40px;
  padding-right: 0 !important;
}
