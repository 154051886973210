@import '~/ui/assets/styles/colors.module.scss';

.createGroup {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  background-color: $color-white;
  cursor: pointer;
  height: 100%;
  position: relative;
}

.header {
  position: sticky;
  top: 0;
  height: 64px;
  display: flex;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: $color-white;
  padding-right: 15px;
}

.search {
  flex: 1;
}

.groupName {
  padding: 15px;
}

.clientSelect {
  padding: 5px 15px 15px;
}

.listTitle {
  font-size: 0.94rem;
  font-weight: 700;
  padding: 15px;
}

.buttonItem {
  width: 100%;
  margin-top: 0;

  & svg {
    margin-left: 5px;
  }

  & span {
    padding-left: 5px;
    font-weight: 700;
  }

  & button {
    height: 50px;
  }
}

.bottomBorder {
  border-bottom: 1px solid $color-grey-4;
}

.userList {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  padding: 0 15px 11px 11px;
}

.scrollable {
  overflow: hidden auto;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  padding-bottom: 60px;
}

.userItem {
  & span {
    font-weight: 600;
  }
}
