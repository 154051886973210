@import '~/ui/assets/styles/colors.module.scss';

.calendarCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $color-grey-6;
  border-bottom-color: $color-red-4;
  border-bottom-width: 2px;
  border-top-color: transparent;
  min-width: 50px;
  flex: 1;
  min-height: 111px;
  height: 100%;
  width: 50px;
  position: relative;
  cursor: pointer;
}

.dayCell {
  border: none;
  min-height: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-bottom: 2px solid $color-grey-6;
  flex-direction: column;
  background-color: none;
  cursor: default;
  background-color: $color-grey-8;
  padding-left: 10px;
}

.firstCell {
  min-width: 226px;
  max-width: 226px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
  border: 1px solid $color-grey-6;
  height: 100%;
  position: sticky;
  left: 0;
  background-color: $color-white;
  z-index: 1;
  border-bottom-width: 2px;
  border-bottom-color: $color-red-4;
  border-top-color: transparent;
  cursor: default;
}

.noBorder {
  border: none;
  border-bottom: 2px solid $color-grey-6;
  background-color: $color-white-4;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.weekend {
  background-color: $color-grey-9 !important;
}

.hidden {
  display: none !important;
}

.dayOfWeek {
  color: $color-grey;
  cursor: default;
  font-weight: 600;
  margin-left: -10px;
}

.today {
  color: $color-red !important;
}

.withContent {
  &:hover::before {
    content: '';
    position: absolute;
    display: flex;
    flex: 1;
    top: -2px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 1px solid $color-red-2 !important;
  }
}

.noHover {
  &:hover::before {
    content: none;
  }
  &:hover {
    background-color: $color-grey-10 !important;
  }
}

.weekMode {
  min-height: 50px;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: row-reverse !important;

  .dayOfWeek {
    margin-left: 0px;
    margin-right: 5px;
  }
}
