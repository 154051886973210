@import '~/ui/assets/styles/colors.module.scss';

.channelName {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background-color: $color-white;
  padding-bottom: 15px;
}

.channelTitle {
  padding-left: 15px;
  flex: 1;
  font-size: 18px;
  font-weight: 700;
}

.channelNameButton {
  width: 30px;
  height: 30px;
  padding: 5px !important;

  & svg {
    width: 15px;
    height: 15px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
}

.channelNameInput {
  padding: 10px 15px 5px;
}
