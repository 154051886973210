@import '~/ui/assets/styles/colors.module.scss';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $color-white;
  justify-content: space-between;

  & svg {
    color: $color-black;
  }
  padding-right: 10px;
}

.sidebarTitle {
  font-size: 18px;
  font-weight: 400;
  margin-left: 15px;
}
