@import '~/ui/assets/styles/colors.module.scss';

.calendar {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: $color-white;
  box-shadow: 0px 1px 4px $color-grey-7;
  margin-top: 20px;
  align-self: stretch;
  padding: 10px 0;
}

.headerSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
}

.calendarSection {
  display: flex;
}

.rightColumn {
  display: flex;
  flex-direction: row;
}

.includeWeekend {
  margin-right: 64px;
  font-weight: 400;
}

.modeActions {
  display: flex;
  align-items: center;
}

.modeButton {
  border-radius: 8px !important;
  width: 92px;
  margin-right: 10px !important;
  font-weight: 400 !important;
  box-shadow: 0px 1px 4px $color-grey-7;
}
