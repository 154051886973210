.buttonsWrapper {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}

.margin {
  margin: 0 10px !important;
}

.marginVertical {
  margin: 20px 0 !important;
}
