@import '~/ui/assets/styles/colors.module.scss';

.medicationInfo {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 600;
  flex: 1;
}

.injectable {
  min-width: 20px;
  height: 20px;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: $color-red-2;
  -webkit-mask-image: url('~/ui/assets/images/injection.svg');
  mask-image: url('~/ui/assets/images/injection.svg');
  margin-left: 5px;
  display: flex;
}

.description {
  color: $color-black-2;
  font-weight: 400;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.grey * {
  color: $color-grey-5;
}

.greyIcon {
  background-color: $color-grey-5;
}

.overdue {
  width: 100%;
  height: 101%;
  padding: 15px;
  position: absolute;
  top: -1px;
  left: 0px;
  background-color:#F5E5E5;
}
.nextDueDate {
  font-weight: 400;
}
.overdueDate {
  color: #D83612;
}
