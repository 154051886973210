@import '~/ui/assets/styles/colors.module.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.itemsWrapper {
  border-radius: 20px;
  border: 1px solid $color-grey-4;
}

.item {
  background-color: white;
  padding: 15px;
  border-bottom: 1px solid $color-grey-4;
  font-weight: 300;
}

.itemHeader {
  display: inline-block;
  width: 100px;
}

.itemDetails {
  color: grey;
  margin-left: 25px;
}

.item:first-of-type {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.item:last-of-type {
  border: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.buttonContainer {
  margin-top: 15px;
}

.uploaderWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.error {
  color: $color-red-3;
}
