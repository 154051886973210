@import '~/ui/assets/styles/colors.module.scss';

.buttonContainer {
  background-color: $color-white;
  margin-top: 10px;
}

.button {
  display: flex;
  flex-direction: row;
  padding: 10px 15px !important;
  background-color: $color-white;
  width: 100%;
  justify-content: flex-start !important;

  & svg {
    margin-right: 10px !important;
    width: 24px;
    height: 24px;
  }
}
