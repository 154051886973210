@import '~/ui/assets/styles/colors.module.scss';

.previewTitle {
  text-align: center;
}

.itemContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

@mixin item {
  font-weight: 400;
}

.alignCenter {
  text-align: center !important;

  .itemTitle {
    text-align: center !important;
  }
}

.itemTitle {
  @include item;
  color: $color-grey-2;
}

.itemValue {
  @include item;
}

.teamMembersList {
  display: flex;
  align-content: flex-start;
}

.teamMembersListItem {
  padding-bottom: 5px;
  height: fit-content;
}

.closeButtonContainer {
  text-align: center;
  margin-bottom: 15px;
}

.avatarContainer {
  display: flex;
  align-items: center;
}
