@import '~/ui/assets/styles/colors.module.scss';

.archivedChannelsListItem {
  display: flex;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  padding: 0 12px;
  background-color: $color-white;
  cursor: pointer;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  border-bottom-width: 1px;
  align-self: stretch;
  border-bottom-color: $color-grey-4;
  flex: 1;
  margin-left: 3px;
  max-width: calc(100% - 45px);
}

.col {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  align-self: stretch;
  padding: 12px 0;
  overflow: hidden;
}

.title {
  color: $color-black;
  font-size: 1.06rem;
  font-weight: 700;
}

.subTitle {
  color: $color-black;
  font-size: 0.94rem;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.rightContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0 12px 10px;
  display: flex;
}

.iconContainer {
  background-color: $color-grey-11;
}
