@import '~/ui/assets/styles/colors.module.scss';

.deliveryTime {
  color: $color-green-4;
  font-size: 14px;
  font-weight: 400;
  margin: 3px 3px;
  align-self: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 2px;
  background-color: $color-green-4;
  -webkit-mask: url(~/ui/assets/images/clockIcon.svg) no-repeat center;
  mask: url(~/ui/assets/images/clockIcon.svg) no-repeat center;
  mask-size: contain;
}

.archivedIcon {
  background-color: $color-black-2;
}

.weekMode {
  align-self: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rowReverse {
  flex-direction: row-reverse;
}

.margin {
  margin-right: 5px;
}

.archived * {
  color: $color-black-2 !important;
}
