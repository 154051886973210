@import '~/ui/assets/styles/colors.module.scss';

.actionCell {
  padding-left: 16px;
}

.button {
  cursor: pointer;
  padding-left: 16px;
}

.link {
  color: black;
  text-decoration: none;

  &:hover {
    color: $color-red-2;
  }
}
