@import '~/ui/assets/styles/colors.module.scss';

.refuseLabel {
  background-color: $color-red-2;
  color: $color-white;
  padding: 0 6px;
  border-radius: 5px;
  overflow: hidden;
  font-size: 14px;
  font-weight: 300;
  margin: 3px 0;
  align-self: center;
  min-height: 18px;
}

.archived {
  background-color: $color-black-2;
  color: $color-white !important;
}
